<template>
  <el-container class="empty-layout" v-if="$store.getters.isAppLoaded">
    <el-main>
      <slot />
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'EmptyLayout',
}
</script>
